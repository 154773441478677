import React, { useEffect, useState } from "react"
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux"
import { FaUser, FaPhone, FaEnvelope, FaBuilding, FaMapMarkerAlt, FaPenAlt } from 'react-icons/fa'

import { onChange } from "../../redux/registration/registrationAction"
import checkboxInput from "./welcome/checkboxInput"
import MultselectInputHelper from "../../components/form/multselectInput"
import { validTab } from "../../redux/tabs/tabsAction"
import AlertCategory from "./alertCategory/alertCategory"
import HelpUr from "../../components/help/help"
import { isDefined } from "../../utils/data"
import Select from 'react-select'
// import makeAnimated from 'react-select/animated';

const cfp_options = [
    { value: 'Acquérir une formation de base', label: 'Acquérir une formation de base' },
    { value: 'Actualiser ses connaissances théoriques', label: 'Actualiser ses connaissances théoriques' },
    { value: 'Acquérir de nouvelles compétences pratiques', label: 'Acquérir de nouvelles compétences pratiques' },
    { value: 'Echanger avec des collègues et partager ses pratiques', label: 'Echanger avec des collègues et partager ses pratiques' },
    { value: 'Pas d\'attente', label: 'Pas d\'attente' },
];

const styles = {
    container: base => ({
        ...base,
        flex: 1,        
        height: 10,
        minHeight: 10,
        fontSize:"12px"
    })
};


const YourData = ({ prevTab, nextTab }) => {

    const dispatch = useDispatch()
    const { information, user } = useSelector(({ registrationReducer }) => registrationReducer)
    // const animatedComponents = makeAnimated();

    // const handleChange = (selectedOption) => {
    //     setSelectedOption({ selectedOption })
    //     console.log(`Option selected:`, this.selectedOption)
    // }

    const onClickNext = (e) => {
        e.preventDefault()
        dispatch(validTab("yourDataTab"))
        nextTab()
    }

    // const onChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {
    // }
    //   handleChange = (selectedOption) => {
    //     this.setState({ selectedOption }, () =>
    //       console.log(`Option selected:`, this.state.selectedOption)
    //     );
    //   };




    //dispatch(submitInformation(user, values, nextTab))




    return (
        <div className={"content yourData col-10 mx-auto txt-left"}>
            

            <p className={"title"}>Démarche qualité formation</p>

            <p className={"small txt-left"}>
                Pour répondre aux exigences de la certification Qualiopi, nous vous prions de bien vouloir renseigner pour chacune des thématiques du congrès :</p>
            <ul>
                <li className={"small"}>
                    votre niveau d’expertise de 0 à 5 (0 pas concerné ; 1 débutant à 5 expert)
                </li>
                <li className={"small"}>
                    vos attentes lors du congrès (Acquérir une formation de base, Actualiser ses connaissances théoriques, Acquérir de nouvelles compétences pratiques, Echanger avec des collègues et partager ses pratiques, Pas d’attente)</li>
            </ul>
            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Addiction</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test1"} id="zer1" />
                    <label for="zer1">0</label>
                    <input type="radio" name={"test1"} id="one1" />
                    <label for="one1">1</label>
                    <input type="radio" name={"test1"} id="two1" />
                    <label for="two1">2</label>
                    <input type="radio" name={"test1"} id="three1" />
                    <label for="three1">3</label>
                    <input type="radio" name={"test1"} id="four1" />
                    <label for="four1">4</label>
                    <input type="radio" name={"test1"} id="five1" />
                    <label for="five1">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-1"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>
            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Clinique</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test2"} id="zer2" />
                    <label for="zer2">0</label>
                    <input type="radio" name={"test2"} id="one2" />
                    <label for="one2">1</label>
                    <input type="radio" name={"test2"} id="two2" />
                    <label for="two2">2</label>
                    <input type="radio" name={"test2"} id="three2" />
                    <label for="three2">3</label>
                    <input type="radio" name={"test2"} id="four2" />
                    <label for="four2">4</label>
                    <input type="radio" name={"test2"} id="five2" />
                    <label for="five2">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-1"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>
            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Enfants et Adolescents / Périnatalité</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test3"} id="zer3" />
                    <label for="zer3">0</label>
                    <input type="radio" name={"test3"} id="one3" />
                    <label for="one3">1</label>
                    <input type="radio" name={"test3"} id="two3" />
                    <label for="two3">2</label>
                    <input type="radio" name={"test3"} id="three3" />
                    <label for="three3">3</label>
                    <input type="radio" name={"test3"} id="four3" />
                    <label for="four3">4</label>
                    <input type="radio" name={"test3"} id="five31" />
                    <label for="five3">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-3"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Nouvelles Technologies / Santé Connectée</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test4"} id="zer4" />
                    <label for="zer4">0</label>
                    <input type="radio" name={"test4"} id="one4" />
                    <label for="one4">1</label>
                    <input type="radio" name={"test4"} id="two4" />
                    <label for="two4">2</label>
                    <input type="radio" name={"test4"} id="three4" />
                    <label for="three4">3</label>
                    <input type="radio" name={"test4"} id="four4" />
                    <label for="four4">4</label>
                    <input type="radio" name={"test4"} id="five4" />
                    <label for="five44">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-4"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Psychiatrie de la Personne Âgée</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test5"} id="zer5" />
                    <label for="zer5">0</label>
                    <input type="radio" name={"test5"} id="one5" />
                    <label for="one5">1</label>
                    <input type="radio" name={"test5"} id="two5" />
                    <label for="two5">2</label>
                    <input type="radio" name={"test5"} id="three5" />
                    <label for="three5">3</label>
                    <input type="radio" name={"test5"} id="four5" />
                    <label for="four5">4</label>
                    <input type="radio" name={"test5"} id="five5" />
                    <label for="five5">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-5"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Psychiatrie et Société</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test6"} id="zer6" />
                    <label for="zer6">0</label>
                    <input type="radio" name={"test6"} id="one6" />
                    <label for="one6">1</label>
                    <input type="radio" name={"test6"} id="two6" />
                    <label for="two6">2</label>
                    <input type="radio" name={"test6"} id="three6" />
                    <label for="three6">3</label>
                    <input type="radio" name={"test6"} id="four6" />
                    <label for="four6">4</label>
                    <input type="radio" name={"test6"} id="five6" />
                    <label for="five6">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-6"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Psychiatrie Médico-Légale</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test7"} id="zer7" />
                    <label for="zer7">0</label>
                    <input type="radio" name={"test7"} id="one7" />
                    <label for="one7">1</label>
                    <input type="radio" name={"test7"} id="two7" />
                    <label for="two7">2</label>
                    <input type="radio" name={"test7"} id="three7" />
                    <label for="three7">3</label>
                    <input type="radio" name={"test7"} id="four7" />
                    <label for="four7">4</label>
                    <input type="radio" name={"test7"} id="five7" />
                    <label for="five7">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-7"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Sciences Fondamentales</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test8"} id="zer8" />
                    <label for="zer8">0</label>
                    <input type="radio" name={"test8"} id="one8" />
                    <label for="one8">1</label>
                    <input type="radio" name={"test8"} id="two8" />
                    <label for="two8">2</label>
                    <input type="radio" name={"test8"} id="three8" />
                    <label for="three8">3</label>
                    <input type="radio" name={"test8"} id="four8" />
                    <label for="four8">4</label>
                    <input type="radio" name={"test8"} id="five8" />
                    <label for="five8">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-8"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>            <div className={"row mb-5"}>
                <div className={"col-12"}>
                    <p className={"small text-center"}><b>Thérapeutique</b></p>
                </div>
                <div className={"col-12 col-md-3 rating_style"}>
                    <p className={"muted small title_select_multi"}><i>Niveau</i></p>
                    <input type="radio" name={"test9"} id="zer9" />
                    <label for="zer9">0</label>
                    <input type="radio" name={"test9"} id="one9" />
                    <label for="one9">1</label>
                    <input type="radio" name={"test9"} id="two9" />
                    <label for="two9">2</label>
                    <input type="radio" name={"test9"} id="three9" />
                    <label for="three9">3</label>
                    <input type="radio" name={"test9"} id="four9" />
                    <label for="four9">4</label>
                    <input type="radio" name={"test9"} id="five9" />
                    <label for="five9">5</label>
                </div>
                <div className={"col-12 col-md-8"}>
                    <p className={"muted small title_select_multi"}><i>Attentes</i></p>
                    {/* <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        options={options}
                        name={"test11"}
                        isMulti
                    /> */}
                    <Select
                        options={cfp_options}
                        onChange={() => console.log("changed")}
                        getOptionLabel={option => option.label}
                        getOptionValue={option => option.value}
                        styles={styles}
                        name={"attente-9"}
                        placeholder={"Sélectionnez vos attentes (plusieurs choix possibles)"}
                        isMulti
                    />
                </div>
            </div>   
            {/* <CheckboxInput placeholder={"I understand this is how The Union will use my data *"}
                           onChange={e => dispatch(onChange(e, true))}
                           name={"registered_cvg"}
                           value={information.registered_cvg.value}
                           disabled={information.registered_cvg.value === true || information.registered_cvg.value === "1"}/> */}


            <div className={"content-footer"}>
                <button className={"sr-btn-next btn btn-secondary"} onClick={prevTab}>Précédent</button>
                <small className={"help"}><HelpUr /></small>
                <button className={"sr-btn-next btn btn-info"} onClick={onClickNext}>Suivant</button>
            </div>
        </div>
    )
}

YourData.propTypes = {
    prevTab: PropTypes.func.isRequired,
    nextTab: PropTypes.func.isRequired,
}

export default YourData
